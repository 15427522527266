/* src/styles/login.css */
.login-container {
  display: flex;
  height: 100vh;
  background-color: #f7f7f7;
}

.login-left {
  width: 50%;
  background-color: #f0f4ff; /* Slightly blue background color */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
  justify-content: center;
  padding: 20px;
  padding-left: 60px; /* Adjust padding to move content left */
}

.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 100px; /* Adjust this value to move the logo downward */
}

.logo-container .logo {
  width: 100px; /* Reduce the logo size */
  margin-right: 10px;
}

.logo-container h1 {
  font-size: 20px;
  color: #007bff;
  margin-left: 10px; /* Space between logo and text */
}

.login-left .branding-image img {
  width: 100%;
}

.login-right {
  width: 50%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.login-right h2 {
  color: #007bff;
  font-size: 36px;
  margin-bottom: 10px;
}

.login-right p {
  font-size: 18px;
  color: #333333;
  margin-bottom: 30px;
}

.login-form .form-group {
  margin-bottom: 20px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.login-form button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

.success-message {
  color: green;
  margin-bottom: 20px;
}

.extra-options {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.extra-options a {
  color: #007bff;
  text-decoration: none;
}

.extra-options .wechat-login {
  display: flex;
  align-items: center;
}

.extra-options .wechat-login img {
  width: 40px;
  margin-left: 10px;
}

/* WeChat Login Specific */
.wechat-qr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.wechat-qr img {
  width: 200px; /* Adjust the size of the QR code */
  height: auto;
}

.language-switcher {
  margin-top: 20px;
  text-align: right;
}

.language-switcher select {
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #dddddd;
}
